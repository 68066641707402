import { HttpLink, from, ApolloLink, RequestHandler } from '@apollo/client'
import config from '@util/config'
import { setContext } from '@apollo/link-context'
import { getJwt, removeJwtFromStore } from '@util/auth'
import { onError } from '@apollo/link-error'
import Router from 'next/router'
import { SentryLink } from 'apollo-link-sentry'

const customFetch = async (uri: string, options: any) => {
    const response = await fetch(uri, options)
    if (response.status >= 500) {
        // or handle 400 errors
        return Promise.reject(response.status)
    }
    return response
}

const httpLink: HttpLink = new HttpLink({
    uri: config.gqlUri,
    fetch: customFetch,
})

const authLink: ApolloLink | RequestHandler | any = setContext((_, { headers }) => {
    const jwtStatus = getJwt()
    return {
        headers: {
            ...headers,
            ...(jwtStatus && { authorization: `Bearer ${jwtStatus}` }),
        },
    }
})

// @TODO refactor
const errorLink: ApolloLink | RequestHandler | any = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            if (err.extensions?.code === 'UNAUTHENTICATED') {
                removeJwtFromStore()
                Router.push('/')
            }
        }
    }
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
            console.log(`[GraphQL error this]: Message: ${message}, Location: ${locations}, Path: ${path}`),
        )
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError}`)
    }
})

const sentryLink = new SentryLink({
    uri: config.gqlUri,
})

const link = from([sentryLink, errorLink, authLink, httpLink])

export default link
