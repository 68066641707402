import { ApolloClient } from '@apollo/client'
import config, { isDev, isServer } from '@util/config'
import cache from './apollo.cache'
import link from './apollo.link'
import { createWithApollo } from './createWithApollo'

const createClient = () =>
    new ApolloClient({
        link,
        uri: config.gqlUri,
        cache,
        connectToDevTools: isDev && !isServer,
        ssrMode: isServer,
    })

export const withApollo = createWithApollo(createClient)
