import App from 'next/app'
import Head from 'next/head'
import { ApolloProvider, Context, ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { NextComponentType, NextPageContext } from 'next'
import { isDev, isProd, isServer } from '@util/config'
import React from 'react'
import { AppBootstrap } from '../app/AppBootstrap'

export type MyPageContext = NextComponentType & {
    getLayout?: (page: any) => any
    authRequied?: boolean
    ssr?: boolean
}

// ? Source
// ? https://github.com/benawad/lireddit/blob/master/web/src/utils/createWithApollo.js

let globalApolloClient = null

export const initOnContext = (ac, ctx: Context) => {
    const inAppContext = Boolean(ctx.ctx)

    if (isDev) {
        if (inAppContext) {
            console.warn(
                'Warning: You have opted-out of Automatic Static Optimization due to `withApollo` in `pages/_app`.\n' +
                    'Read more: https://err.sh/next.js/opt-out-auto-static-optimization\n',
            )
        }
    }

    const apolloClient = ctx.apolloClient || initApolloClient(ac, ctx.apolloState || {}, inAppContext ? ctx.ctx : ctx)

    apolloClient.toJSON = () => null

    ctx.apolloClient = apolloClient
    if (inAppContext) {
        ctx.ctx.apolloClient = apolloClient
    }

    return ctx
}

const initApolloClient = (
    apolloClient,
    initialState: NormalizedCacheObject,
    ctx: NextPageContext,
): ApolloClient<NormalizedCacheObject> => {
    if (isServer) {
        return createApolloClient(apolloClient(ctx), initialState, ctx)
    }

    if (!globalApolloClient) {
        globalApolloClient = createApolloClient(apolloClient(ctx), initialState, ctx)
    }

    return globalApolloClient
}

export const createWithApollo = (ac: any) => {
    return ({ ssr = false } = {}) =>
        (PageComponent: MyPageContext) => {
            const WithApollo = ({ apolloClient, apolloState, ...pageProps }) => {
                let client
                if (apolloClient) {
                    client = apolloClient
                } else {
                    client = initApolloClient(ac, apolloState, undefined)
                }

                return (
                    <ApolloProvider client={client}>
                        <AppBootstrap Component={PageComponent} {...pageProps} />
                    </ApolloProvider>
                )
            }

            if (!isProd) {
                const displayName = PageComponent.displayName || PageComponent.name || 'Component'
                WithApollo.displayName = `withApollo(${displayName})`
            }

            if (ssr || PageComponent.getInitialProps) {
                WithApollo.getInitialProps = async (ctx) => {
                    const inAppContext = Boolean(ctx.ctx)
                    const { apolloClient } = initOnContext(ac, ctx)

                    let pageProps = {}
                    if (PageComponent.getInitialProps) {
                        pageProps = await PageComponent.getInitialProps(ctx)
                    } else if (inAppContext) {
                        pageProps = await App.getInitialProps(ctx)
                    }

                    if (isServer) {
                        const { AppTree } = ctx
                        if (ctx.res && ctx.res.finished) {
                            return pageProps
                        }

                        if (ssr && AppTree) {
                            try {
                                const { getDataFromTree } = await import('@apollo/client/react/ssr')

                                let props
                                if (inAppContext) {
                                    props = { ...pageProps, apolloClient }
                                } else {
                                    props = { pageProps: { ...pageProps, apolloClient } }
                                }

                                await getDataFromTree(<AppTree {...props} />)
                            } catch (error) {
                                console.error('Error while running `getDataFromTree`', error)
                            }

                            Head.rewind()
                        }
                    }

                    return {
                        ...pageProps,
                        apolloState: apolloClient.cache.extract(),
                        apolloClient: ctx.apolloClient,
                    }
                }
            }

            return WithApollo
        }
}

function createApolloClient(apolloClient, initialState, ctx): ApolloClient<NormalizedCacheObject> {
    apolloClient.ssrMode = Boolean(ctx)
    apolloClient.cache.restore(initialState)

    return apolloClient
}
